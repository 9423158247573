<template>
  <div style="max-width:700px; margin:auto;">
    <h1>POLÍTICA DE PRIVACIDAD</h1>
    <br>
    <h2>1. DATOS DEL RESPONSABLE DEL TRATAMIENTO</h2>
    <p>El responsable es DEVANCE VENTURES S.L. de aquí en adelante “abogadus”, provisto de CIF B09695081, y cuyos datos de contacto son:</p>
    <p>● Dirección: Pi y Margall 17. Santa Cruz de Tenerife. 38003</p>
    <p>● Teléfono de contacto: 643943764</p>
    <p>● Email de contacto: charlie@abogadus.com</p>
    <p>1.1. Normativa aplicable</p>
    <p>Nuestra Política de Privacidad se ha diseñado de acuerdo con el Reglamento General de Protección de Datos de la UE 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos), y la Ley Orgánica 3/2018 del 5 de diciembre, de Protección de Datos de Carácter Personal y Garantía de los Derechos Digitales.</p>
    <p>Al facilitarnos sus datos, Usted declara haber leído y conocer la presente Política de Privacidad, prestando su consentimiento inequívoco y expreso al tratamiento de sus datos personales de acuerdo a las finalidades y términos aquí expresados.</p>
    <p>La Empresa podrá modificar la presente Política de Privacidad para adaptarla a las novedades legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos. Estas condiciones de privacidad podrán ser complementadas por el Aviso Legal, Política de Cookies y las Condiciones Generales que, en su caso, se recojan para determinados productos o servicios, si dicho acceso supone alguna especialidad en materia de protección de datos de carácter personal.</p>
    <h2>2. FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h2>
    <p>El tratamiento que realizamos de sus datos personales responde a las siguientes finalidades:</p>
    <p>- Proporcionarle información relacionada con los productos y servicios que ofrece nuestra empresa y que detallan en este website o app.</p>
    <p>- Proporcionar información relevante para el desarrollo del servicio de forma oportuna, agendamiento de citas, cancelaciones, enlaces de acceso a sesiones, entre otras actividades.</p>
    <p>- Realizar la contratación de nuestros servicios mediante la aceptación del correspondiente presupuesto / pedido y/o la firma de un contrato mercantil.</p>
    <p>- Enviar por correo electrónico y/o postal las noticias y novedades acerca de nuestra entidad, así como las actualizaciones de nuestro catálogo de productos y servicios.</p>
    <p>2.1. Plazo de Conservación de sus datos</p>
    <p>Conservaremos sus datos personales desde que nos dé su consentimiento hasta que lo revoque o bien solicite la limitación del tratamiento. En tales casos, mantendremos sus datos de manera bloqueada durante los plazos legalmente exigidos.</p>
    <h2>3. LEGITIMACIÓN Y DATOS RECABADOS</h2>
    <p>La legitimación para el tratamiento de sus datos es el consentimiento expreso otorgado mediante un acto positivo y afirmativo (rellenar el formulario correspondiente y marcar la casilla de aceptación de esta política) en el momento de facilitarnos sus datos personales.</p>
    <p>3.1. Consentimiento para tratar sus datos</p>
    <p>Al rellenar los formularios, marcar la casilla “Acepto la Política de Privacidad” y hacer clic para enviar los datos, o al remitir correos electrónicos a la Empresa a través de las cuentas habilitadas al efecto, el Usuario manifiesta haber leído y aceptado expresamente la presente política de privacidad, y otorga su consentimiento inequívoco y expreso al tratamiento de sus datos personales conforme a las finalidades indicadas.</p>
    <p>3.2. Categorías de datos</p>
    <p>Los datos que se recaban se refieren a la categoría de datos identificativos, como pueden ser: Nombre y Apellidos, Teléfono, Dirección Postal, Empresa, Correo electrónico, así como la dirección IP desde donde accede al formulario de recogida de datos.</p>
    <h2>4. MEDIDAS DE SEGURIDAD</h2>
    <p>Dentro de nuestro compromiso por garantizar la seguridad y confidencialidad de sus datos de carácter personal, le informamos que se han adoptado las medidas de índole técnica y organizativas necesarias para garantizar la seguridad de los datos de carácter personal y evitar su alteración, pérdida, tratamiento o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que estén expuestos, según el Art. 32 del RGPD EU 679/2016.</p>
    <h2>5. CESIÓN DE DATOS</h2>
    <p>No se prevén cesiones de datos ni transferencias internacionales de sus datos, a excepción de las autorizadas por la legislación fiscal, mercantil y de telecomunicaciones, así como en aquellos casos en los que una autoridad judicial nos lo requiera.</p>
    <h2>6. DERECHOS DEL USUARIO</h2>
    <p>Cualquier interesado tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le concierne, o no. Las personas interesadas tienen derecho a acceder a sus datos personales, así como a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos. En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones. Por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. El Responsable del fichero dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.</p>
    <p>De acuerdo con la legislación vigente tiene los siguientes derechos: derecho a solicitar el acceso a sus datos personales, derecho a solicitar su rectificación o supresión, derecho a solicitar la limitación de su tratamiento, derecho a oponerse al tratamiento, derecho a la portabilidad de los datos y así mismo, a revocar el consentimiento otorgado. Asimismo, tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos.</p>
    <p>6.1. ¿Cómo ejercitar mis derechos?</p>
    <p>Para ejercer sus derechos, debe dirigirse al responsable, solicitando el correspondiente formulario para el ejercicio del derecho elegido. Opcionalmente, puede acudir a la Autoridad de Control competente para obtener información adicional acerca de sus derechos. Los datos de contacto para el ejercicio de sus derechos son el teléfono 643943764, y e-Mail: charlie@abogadus.com. Recuerde acompañar una copia de un documento que nos permita identificarle.</p>
    <h2>7. CONSENTIMIENTO PARA ENVÍO DE COMUNICACIONES ELECTRÓNICAS</h2>
    <p>Así mismo, y de acuerdo con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, completando el formulario de recogida de datos y marcando la correspondiente casilla “Acepto el envío de comunicaciones electrónicas”, está otorgando el consentimiento expreso para enviarle a su dirección de correo electrónico, teléfono, u otro medio electrónico en envío de información acerca de la Empresa.</p>
    <br><br><br><br><br>

  <p style="font-family: Basier circle;
    font-weight: bold;
    font-size: 30px;
    color: #111747;
    margin-left: 4%;">POLÍTICA DE COOKIES</p>
  <br><br>

<h2>1.- ANTECEDENTES</h2>
<p><span style="font-weight: 400;">De conformidad con la normativa espa&ntilde;ola que regula el uso de cookies en relaci&oacute;n a la prestaci&oacute;n de servicios de comunicaciones electr&oacute;nicas, recogida en el art&iacute;culo 22.2 de la Ley 34/2002, del 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico, le informamos sobre las cookies utilizadas en el sitio web de <strong>DEVANCE VENTURES S.L.</strong> y el motivo de su uso. </span></p>
<p>&nbsp;</p>
<h2>2.- &iquest;QU&Eacute; SON LAS COOKIES?</h2>
<p><span style="font-weight: 400;">Una cookie es un fichero que se descarga en su ordenador al acceder a determinadas p&aacute;ginas web. Las cookies permiten a una p&aacute;gina web, entre otras cosas, almacenar y recuperar informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n de un usuario o de su equipo y, dependiendo de la informaci&oacute;n que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. </span><span style="font-weight: 400;">Las cookies se asocian &uacute;nicamente a un usuario an&oacute;nimo y su ordenador o dispositivo y no proporcionan referencias que permitan conocer sus datos personales.&nbsp;</span></p>
<p><br /><br /></p>
<h2>3.- TIPOS DE COOKIES</h2><br>
<p><strong>3.1.- Seg&uacute;n la entidad que las gestiona</strong></p>
<p>a. - Cookies propias. Son aqu&eacute;llas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</p>
<p>b.- Cookies de terceros. Son aqu&eacute;llas que se env&iacute;an al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a trav&eacute;s de las cookies.</p>
<p>&nbsp;</p>
<p><strong>3.2.- Seg&uacute;n la finalidad</strong></p>
<p><span style="font-weight: 400;">Existen muchas finalidades para el uso de las cookies. Seg&uacute;n la finalidad para la que se traten los datos obtenidos a trav&eacute;s de las cookies, algunas de las finalidades pueden ser:</span></p>

<li><strong>Cookies t&eacute;cnicas:</strong><span style="font-weight: 400;"> son aqu&eacute;llas que permiten al usuario la navegaci&oacute;n a trav&eacute;s de una p&aacute;gina web, plataforma o aplicaci&oacute;n y la utilizaci&oacute;n de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la gesti&oacute;n y operativa de la p&aacute;gina web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tr&aacute;fico y la comunicaci&oacute;n de datos, identificar la sesi&oacute;n, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, gestionar el pago, controlar el fraude vinculado a la seguridad del servicio, realizar la solicitud de inscripci&oacute;n o participaci&oacute;n en un evento, contar visitas a efectos de la facturaci&oacute;n de licencias del software con el que funciona el servicio (sitio web,&nbsp; plataforma o aplicaci&oacute;n), utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de v&iacute;deos o sonido, habilitar contenidos din&aacute;micos (por ejemplo, animaci&oacute;n de carga de un texto o imagen) o compartir contenidos a trav&eacute;s de redes sociales.</span></li>

<p><span style="font-weight: 400;">Las cookies t&eacute;cnicas estar&aacute;n exceptuadas del cumplimiento de las obligaciones establecidas en el art&iacute;culo 22.2 de la LSSI cuando permitan prestar el servicio solicitado por el usuario, como ocurre en el caso de las cookies enumeradas en los p&aacute;rrafos anteriores. Sin embargo, si estas cookies se utilizan tambi&eacute;n para finalidades no exentas (por ejemplo, para fines publicitarios comportamentales), quedar&aacute;n sujetas a dichas obligaciones.</span></p>

<li><strong>Cookies de preferencias o personalizaci&oacute;n</strong><span style="font-weight: 400;">: son aqu&eacute;llas que permiten recordar informaci&oacute;n para que el usuario acceda al servicio con determinadas caracter&iacute;sticas que pueden diferenciar su experiencia de la de otros usuarios, como, por ejemplo, el idioma, el n&uacute;mero de resultados a mostrar cuando el usuario realiza una b&uacute;squeda, el aspecto o contenido del servicio en funci&oacute;n del tipo de navegador a trav&eacute;s del cual el usuario accede al servicio o de la regi&oacute;n desde la que accede al servicio, etc.</span></li>

<p><span style="font-weight: 400;">Si es el propio usuario quien elige esas caracter&iacute;sticas (por ejemplo, si selecciona el idioma de un sitio web clicando en el icono de la bandera del pa&iacute;s correspondiente), las cookies estar&aacute;n exceptuadas de las obligaciones del art&iacute;culo 22.2 de la LSSI por considerarse un servicio expresamente solicitado por el usuario, y ello siempre y cuando las cookies obedezcan exclusivamente a la finalidad seleccionada.</span></p>

<li><strong>Cookies de an&aacute;lisis o medici&oacute;n:</strong><span style="font-weight: 400;"> son aquellas que permiten al responsable de las mismas el seguimiento y an&aacute;lisis del comportamiento de los usuarios de los sitios web a los que est&aacute;n vinculadas, incluida la cuantificaci&oacute;n de los impactos de los anuncios. La informaci&oacute;n recogida mediante este tipo de cookies se utiliza en la medici&oacute;n de la actividad de los sitios web, aplicaci&oacute;n o plataforma, con el fin de introducir mejoras en funci&oacute;n del an&aacute;lisis de los datos de uso que hacen los usuarios del servicio.</span></li>
<li><strong>Cookies de publicidad comportamental:</strong><span style="font-weight: 400;"> son aquellas que almacenan informaci&oacute;n del comportamiento de los usuarios obtenida a trav&eacute;s de la observaci&oacute;n continuada de sus h&aacute;bitos de navegaci&oacute;n, lo que permite desarrollar un perfil espec&iacute;fico para mostrar publicidad en funci&oacute;n del mismo.</span></li>
<br><br>
<p><strong>3.3.- Seg&uacute;n el plazo de tiempo que permanecen activadas</strong></p>

<li><strong>Cookies de sesi&oacute;n:</strong><span style="font-weight: 400;"> son aquellas dise&ntilde;adas para recabar y almacenar datos mientras el usuario accede a una p&aacute;gina web. Se suelen emplear para almacenar informaci&oacute;n que solo interesa conservar para la prestaci&oacute;n del servicio solicitado por el usuario en una sola ocasi&oacute;n (por ejemplo, una lista de productos adquiridos) y desaparecen al terminar la sesi&oacute;n.</span></li>
<li><strong>Cookies persistentes:</strong><span style="font-weight: 400;"> son aquellas en las que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios a&ntilde;os.&nbsp;</span></li>

<p>&nbsp;</p>
<h2>4.- LISTADO DE COOKIES UTILIZADAS POR ESTE SITIO WEB</h2>
<p><span style="font-weight: 400;">La utilizaci&oacute;n de las&nbsp;cookies&nbsp;ofrece ventajas en la prestaci&oacute;n de servicios dentro de lo que se conoce como la sociedad de la informaci&oacute;n, puesto que, facilita al usuario la navegaci&oacute;n y el acceso a los diferentes servicios que ofrece este sitio web; evita al usuario tener que configurar las caracter&iacute;sticas generales predefinidas cada vez que accede al sitio web; favorece la mejora del funcionamiento y de los servicios prestados a trav&eacute;s de este sitio web, tras el correspondiente an&aacute;lisis de la informaci&oacute;n obtenida a trav&eacute;s de las&nbsp;cookies&nbsp;instaladas.</span></p>
<p><span style="font-weight: 400;">En concreto este sitio web utiliza las siguientes cookies:</span></p>
<br><br><br>
<table style="">
<tbody>
<tr>
<td>
<p><strong>Nombre</strong></p>
</td>
<td>
<p><strong>Entidad&nbsp;</strong></p>
</td>
<td>
<p><strong>Caducidad</strong></p>
</td>
<td>
<p><strong>Finalidad</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">fbase_key</span></p>
</td>
<td>
<p><span style="font-weight: 400;"> De Terceros (Google FIREBASE)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">60 D&iacute;as</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Se usa para identificar a los usuarios cuando inician sesi&oacute;n.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">aka_debug</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Vimeo)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Sesi&oacute;n</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Vimeo establece esta cookie que es esencial para que el sitio web reproduzca la funcionalidad de video.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">debug</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Tidio)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Nunca</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Esta informaci&oacute;n hace posible ponerse en contacto con el soporte a trav&eacute;s de un chat interactivo.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">_fw_crm_v</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Tidio)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 a&ntilde;o</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Esta informaci&oacute;n hace posible ponerse en contacto con el soporte a trav&eacute;s de un chat interactivo.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">_ga_VXEMN5J3EZ</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Google Analytics)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2 a&ntilde;os</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Recopilar informaci&oacute;n del uso del sitio web para an&aacute;lisis del comportamiento agregado de los usuarios..&nbsp;</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">_ga</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Google Analytics)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">2 a&ntilde;os</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Calcula los datos de visitantes, sesiones y campa&ntilde;as y tambi&eacute;n realiza un seguimiento del uso del sitio para el informe anal&iacute;tico del sitio. La cookie almacena informaci&oacute;n de forma an&oacute;nima y asigna un n&uacute;mero generado aleatoriamente para reconocer a visitantes &uacute;nicos.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">mp_2649b38727c9b5e606e22b9e7174e7f0_mixpanel</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Mixpanel)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 a&ntilde;os</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Sus datos se pueden utilizar para comprender c&oacute;mo mejorar la experiencia del usuario, as&iacute; como para desarrollar nuevas funciones.</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">mp_40db99e2f65c2956cefaf74f7b404e34_mixpanel</span></p>
</td>
<td>
<p><span style="font-weight: 400;">De Terceros (Mixpanel)</span></p>
</td>
<td>
<p><span style="font-weight: 400;">1 a&ntilde;os</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Sus datos se pueden utilizar para comprender c&oacute;mo mejorar la experiencia del usuario, as&iacute; como para desarrollar nuevas funciones.</span></p>
</td>
</tr>
</tbody>
</table>
<p>&nbsp;</p>
<p><strong><br /></strong><strong>5.- REVOCACI&Oacute;N Y ELIMINACI&Oacute;N DE COOKIES</strong></p>
<p><span style="font-weight: 400;">En todo momento podr&aacute; acceder a la configuraci&oacute;n de su navegador </span><span style="font-weight: 400;">aceptando o rechazando todas las cookies, o bien seleccionar aqu&eacute;llas cuya instalaci&oacute;n admite y cu&aacute;les no, siguiendo los procedimientos propios del navegador que utilice.</span></p>
<p>&nbsp;</p>
<p><strong><br /></strong><strong>6.- TERCEROS PRESTADORES DE SERVICIOS</strong></p>
<p><span style="font-weight: 400;">Los terceros prestadores de servicios con los que hemos contratado alg&uacute;n servicio para el que es necesario la utilizaci&oacute;n de cookies son los que se listan a continuaci&oacute;n:</span></p>
<table>
<tbody>
<tr>
<td>
<p><strong>Proveedor</strong></p>
</td>
<td>
<p><strong>Finalidad del Servicio y de la Cookie</strong></p>
</td>
<td>
<p><strong>Informaci&oacute;n del Proveedor</strong></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">GOOGLE Inc.</span></p>
</td>
<td>
<p><span style="font-weight: 400;"> An&aacute;lisis estad&iacute;stico del sitio web a nivel de contabilizaci&oacute;n de visitantes, p&aacute;ginas visitadas, palabras clave utilizadas y similares</span></p>
</td>
<td>
<p><span style="font-weight: 400;">http://www.google.es/intl/es/policies/privacy/</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">GOOGLE Inc.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Gesti&oacute;n de sesiones de usuarios.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">https://firebase.google.com/support/privacy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Vimeo.com, Inc.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Uso de videos dentro del sitio web.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">https://vimeo.com/privacy</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Tidio LLC</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Chat de soporte.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">https://www.tidio.com/privacy-policy/</span></p>
</td>
</tr>
<tr>
<td>
<p><span style="font-weight: 400;">Mixpanel International, Inc</span></p>
</td>
<td>
<p><span style="font-weight: 400;">Medici&oacute;n de eventos dentro del sitio web.</span></p>
</td>
<td>
<p><span style="font-weight: 400;">https://mixpanel.com/legal/privacy-policy/</span></p>
</td>
</tr>
</tbody>
</table>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
  components: {},
  metaInfo () {
    return {
      title: 'emitus | Privacy Policy',
      meta: [
        {
          name: 'description',
          content: 'The easiest and most efficient way to sell online sessions.'
        },
        {
          name: 'og:description',
          content: 'The easiest and most efficient way to sell online sessions.'
        },
        {
          name: 'site_name',
          content: 'Emitus'
        },
        {
          name: 'og:site_name',
          content: 'Emitus'
        },
        {
          name: 'image',
          content: 'https://emituscdn.com/emitus-share.jpg'
        },
        {
          name: 'og:image',
          content: 'https://emituscdn.com/emitus-share.jpg'
        },
        {
          name: 'title',
          content: 'Abogadus | Consultas Legales Online'
        },
        {
          name: 'og:title',
          content: 'Abogadus | Consultas Legales Online'
        },

        {
          name: 'MobileOptimized',
          content: 'width'
        },
        {
          name: 'HandheldFriendly',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'width'
        },
        {
          name: 'apple-mobile-web-app-status-bar-style',
          content: 'black-translucent'
        },
        {
          name: 'theme-color',
          content: '#000000'
        },
        {
          name: 'apple-mobile-web-app-title',
          content: 'Emitus'
        },
        {
          name: 'mobile-web-app-capable',
          content: 'yes'
        },
        {
          name: 'og:type',
          property: 'og:type',
          content: 'website'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  padding-top: 10%;
}

h1 {
  font-family: Basier circle;
  font-weight: bold;
  font-size: 30px;
  color: #111747;
  margin-left: 4%;
}

h2 {
  font-family: Basier circle;
  font-size: 19px;
  margin-top: 5%;
  margin-left: 5%;
  font-weight: bolder;
  color: #111747;
}

h3 {
  margin-left: 5%;
  font-family: Basier circle;
  font-size: 15px;
  color: #111747;
}

p {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Nunito";
  font-size: 13px;
  line-height: 28px;
  color: #444444;
  margin-left: 7%;
  margin-right: 7%;
}

li {
  font-display: Basier circle;
  font-size: 12px;
  margin-left: 7%;
}
</style>
